import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ProjectManagement = () => (
  <Layout>
    <SEO title="Project Management Database" />

    <div className="page-content">
      <div className="container">
        <h1 className="display-4 text-orange">Project Management Database</h1>
        <p>
        Leverage technology, data, and automation at all points of infrastructure development and management –  from acquiring land rights and tracking right of way to identifying threats, analyzing risk, managing one call tickets, and alerting qualified workers before damages can occur.
        </p>
        <h2 className="my-4">Your Data, The Right Way</h2>
        <p>
        Irth's market-leading SaaS platform improves resilience and reduces risk in the sustainable delivery of essential services that millions of people and businesses rely on every day.
        </p>
        <p>
        Energy, utility, and telecom companies across the U.S. and Canada trust Irth for damage prevention, training, asset inspections, and land management solutions.
        </p>
        <div className="text-center mt-5">
          <a
            className="btn-primary"
            href="https://www.irthsolutions.com/category/case-studies/"
            target="_blank"
            rel="noreferrer"
          >
            Irth Solutions Projects
          </a>
        </div>
      </div>
    </div>
  </Layout>
)

export default ProjectManagement
